import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { ButtonPrimary } from "../../../components/widgets/button";
import { ContentPlusDecor } from "../../../components/widgets/content-plus-decor";
import { FaBullhorn } from "react-icons/fa";
import { useMembership } from "../../../utils/member-data";
export const ContactFormGoogle = () => {
  const membership = useMembership();
  const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLSewfkP7_ttPugg_jFgTmua9yATHOXkxRk1mfDYbggppRB8EEw/viewform?embedded=true".concat(membership?.user?.firstName ? `&entry.1622336807=${membership.user.firstName} ${membership.user.lastName}` : "", membership?.user?.email ? `&entry.381198139=${membership.user.email}` : "");
  return <iframe title="gform" src={formUrl} width="100%" height="900" frameBorder="0" marginHeight="0" marginWidth="0">
            Loading…
        </iframe>;
};
export const ContactForm = () => <div id="support-form" css={`
            h1 {
                display: none;
            }
        `}>
    </div>;
export const _frontmatter = {};
const layoutProps = {
  ContactFormGoogle,
  ContactForm,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <h1>{`Contact Us`}</h1>
    <Breadcrumbs to="/company/" text="Company" mdxType="Breadcrumbs" />
    <ContentPlusDecor decor={<FaBullhorn mdxType="FaBullhorn" />} mdxType="ContentPlusDecor">
      <h2>{`We Love Hearing From You!`}</h2>
      <p>{`Whether you have questions, concerns, or just want to say hi, feel free to use this form to contact us. We’ll get back to you as soon as possible.`}</p>
      <ContactForm mdxType="ContactForm" />
    </ContentPlusDecor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      